<template>
  <div>
    <el-table
      ref="tableDatas"
      v-loading="tableDatasLoading"
      :data="tableDatas"
      :header-cell-style="{ background: '#fafafa' }"
      tooltip-effect="dark"
      max-height="400px"
    >
      <el-table-column :label="$t('page.No')" type="index" width="70" align="center" />

      <template v-if="tableKey==='warehouse'">
        <el-table-column prop="warehouseName" min-width="100px" label="仓库" />
      </template>
      <el-table-column prop="style" label="Style" min-width="100" />
      <el-table-column prop="color" label="Color" min-width="100" />
      <el-table-column prop="size" label="Size" min-width="100" />
      <template v-if="tableKey==='style'">
        <el-table-column prop="totalReleasePairs" label="在库总数" min-width="120" />
        <el-table-column prop="totalLockPairs" min-width="100px" label="锁定总数" />
        <el-table-column prop="inProductionPairs" min-width="100px" label="在产" />
        <el-table-column prop="releaseFzPairs" min-width="100px" label="FZ在库" />
        <el-table-column prop="lockFzPairs" min-width="100px" label="FZ锁定" />
        <el-table-column prop="wayFzPairs" label="FZ在途" min-width="100px" />
        <el-table-column prop="releaseNJPairs" label="NJ在库" min-width="80px" />
        <el-table-column prop="lockNJPairs" label="NJ锁定" min-width="80px" />
        <el-table-column prop="wayNJPairs" label="NJ在途" min-width="80px" />
        <el-table-column prop="releaseILPairs" label="IL在库" min-width="80px" />
        <el-table-column prop="lockILPairs" label="IL锁定" min-width="80px" />
        <el-table-column prop="wayILPairs" label="IL在途" min-width="80px" />
      </template>
      <template v-if="tableKey==='warehouse'">
        <el-table-column prop="inProductionPairs" min-width="100px" label="在产" />
        <el-table-column prop="releasePairs" min-width="100px" label="在库" />
        <el-table-column prop="lockPairs" min-width="100px" label="锁定" />
        <el-table-column prop="wayPairs" min-width="100px" label="在途" />
      </template>
    </el-table>
  </div>
</template>

<script>
export default {
  props: {
    tableDatas: {
      type: Array,
      default() {
        return []
      }
    },
    tableDatasLoading: {
      type: Boolean,
      default: false
    },
    tableKey: {
      type: String,
      default: ''
    }
  },
  data() {
    return {

    }
  },
  created() {

  },
  mounted() {

  },
  methods: {

  }
}
</script>

<style scoped lang="scss">

</style>
