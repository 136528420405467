<template>
  <div>
    <el-form ref="form" :model="form" label-width="100px" style="display: flex">
      <div class="el-lt" style="width: 85%">
        <el-row :span="24">
          <el-col :span="8">
            <el-form-item label="仓库" :class="$i18n.locale">
              <el-select
                v-model="form.warehouseId"
                clearable
                filterable
                collapse-tags
                :placeholder="$t('page.selectPlaceholder')"
              >
                <el-option
                  v-for="item in warehouseOption"
                  :key="item.id"
                  :label="item.warehouseName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="STYLE" :class="$i18n.locale">
              <el-select v-model="form.style" value-key="id" clearable filterable collapse-tags>
                <el-option
                  v-for="item in styleOptions"
                  :key="item.id"
                  :label="item.styleName"
                  :value="item.styleName"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="COLOR" :class="$i18n.locale">
              <el-select v-model="form.color" value-key="id" clearable filterable collapse-tags @visible-change="_queryColorList">
                <el-option
                  v-for="item in colorOptions"
                  :key="item.id"
                  :label="item.colorName"
                  :value="item.colorName"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="SIZE" :class="$i18n.locale">
              <el-select v-model="form.sizes" value-key="id" clearable filterable collapse-tags @visible-change="_querySizeList">
                <el-option v-for="item in sizeOptions" :key="item.id" :label="item.size" :value="item.size" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

      </div>
      <el-row class="el-rt" style="width: 15%">
        <el-col class="row-center">
          <el-button type="primary" class="ml-4" :loading="tableDatasLoading" @click="queryClick(1)">{{
            $t("page.search")
          }}</el-button>
          <el-button @click="resetClick()">{{ $t("page.reset") }}</el-button>
        </el-col>
      </el-row>
    </el-form>
    <el-row>
      <el-button type="primary" class="mb-3" @click="add">新建申请单</el-button>
      <el-tabs v-model="type" @tab-click="queryClick(1)">
        <el-tab-pane label="仓库维度" name="warehouse">
          <QueryTable :table-key="'warehouse'" :table-datas="tableDatas" :table-datas-loading="tableDatasLoading" />
        </el-tab-pane>
        <el-tab-pane label="Style维度" name="style">
          <QueryTable :table-key="'style'" :table-datas="tableDatas" :table-datas-loading="tableDatasLoading" />
        </el-tab-pane>
      </el-tabs>
    </el-row>
    <Paging :pager="pager" end class="mt-3" @pagination="pagerUpdate" />
  </div>
</template>

<script>
import QueryTable from '../safestock-applicationForm/components/QueryTable'
import Paging from '@/components/Pagination'
import { queryStyleList, querySizeList, queryColorList, queryWarehouseList } from '@/api/scm-api'
import { safeStylePage, safeWarehousePage } from '@/api/safestock'
export default {
  components: { QueryTable, Paging },

  data() {
    return {
      form: {

      },
      type: 'warehouse',
      tableDatas: [],
      tableDatasLoading: false,
      warehouseOption: [],
      styleOptions: [],
      colorOptions: [],
      sizeOptions: [],
      pager: {
        current: 1,
        size: 20,
        total: 0
      }
    }
  },
  computed: {
    queryParams() {
      return Object.assign({}, this.form, this.pager)
    }
  },
  created() {

  },
  mounted() {
    this._queryStyleList()
    this._queryWarehouseList()
    this.queryClick(1)
  },
  methods: {
    resetClick() {
      this.form = this.$options.data.call(this).form
      this.queryClick(1)
    },
    async queryClick(flag) {
      try {
        this.tableDatasLoading = true
        flag && flag === 1 ? this.pager.current = 1 : ''
        if (this.type === 'style') {
          const { code, datas: { pager, records }} = await safeStylePage(this.queryParams)
          if (code === 0) {
            this.pager = pager
            this.tableDatas = records
            this.tableDatasLoading = false
          }
        } else {
          const { code, datas: { pager, records }} = await safeWarehousePage(this.queryParams)
          if (code === 0) {
            this.pager = pager
            this.tableDatas = records
            this.tableDatasLoading = false
          }
        }
      } catch (err) {
        console.log(err)
      } finally {
        this.tableDatasLoading = false
      }
    },
    pagerUpdate(val) {
      this.pager = val
      this.queryClick()
    },
    add() {
      this.$router.push({
        path: '../safestock-applicationForm/add',
        append: 'true'
      })
    },
    // size
    async _querySizeList() {
      const { datas } = await querySizeList()
      this.sizeOptions = datas
    },
    // style
    async _queryStyleList() {
      const { datas } = await queryStyleList()
      this.styleOptions = datas
    },
    // color
    async _queryColorList() {
      const { datas } = await queryColorList()
      this.colorOptions = datas
    },
    async _queryWarehouseList() {
      const { datas } = await queryWarehouseList()
      this.warehouseOption = datas
    }
  }
}
</script>

<style scoped lang="scss">

</style>
